import React from "react";
import { onChange } from "./profileFunctions";

const Boards = ({ formData, setFormData }) => {
  const { classPresident, boardOfTrustees, boardOfEducation, boardsComment } =
    formData;

  return (
    <div style={{ borderTop: "1px solid #fff", color: "white" }}>
      <h5>
        Have you or any family members been on any of the following boards?
      </h5>
      <div className="row mt-3 pt-2">
        <div className="col-md-4 col-sm-6">
          <label className="labels">Class President</label>
          <input
            type="checkbox"
            className="form-control"
            name="classPresident"
            value={classPresident}
            checked={classPresident}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="labels">Board of Trustees</label>
          <input
            type="checkbox"
            className="form-control"
            name="boardOfTrustees"
            value={boardOfTrustees}
            checked={boardOfTrustees}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="labels">Board of Education</label>
          <input
            type="checkbox"
            className="form-control"
            name="boardOfEducation"
            value={boardOfEducation}
            checked={boardOfEducation}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="labels">
            If the position was held by anyone other than yourself please
            specify
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Person's Name"
            name="boardsComment"
            value={boardsComment}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
      </div>
    </div>
  );
};

export default Boards;
