import React from "react";
import { Link, useHistory } from "react-router-dom";
import browserRoutes from "../../Routes/browserRoutes";
import { UserContext } from "../../App";

const Navbar = () => {
  const [user, setUser] = React.useContext(UserContext);
  const history = useHistory();

  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    setUser(null);
    history.push(browserRoutes.AUTH);
  };

  const toAdminCenter = (e) => {
    e.preventDefault();
    history.push(browserRoutes.ALL_PROFILES);
  };

  const toHaftrOrg = (e) => {
    e.preventDefault();
    window.location = "https://www.haftr.org/";
  };

  return (
    <nav className="navbar">
      <img
        width="300px"
        src="images/hili-haftr-hillel-cropped2.png"
        alt="..."
        style={{ cursor: "pointer" }}
        onClick={() => history.push(browserRoutes.LANDING_PAGE)}
      />
      <div className="d-flex">
        <div>
          <button className="nav-link mr-1 haftr-button" onClick={toHaftrOrg}>
            HAFTR.org
          </button>
        </div>
        <div className="dropdown">
          <button
            id="donateDropdownButton"
            className="nav-link mr-1 dropdown-toggle haftr-button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Giving
          </button>
          <div class="dropdown-menu" aria-labelledby="donateDropdownMenu">
            <a
              class="dropdown-item"
              href="https://fs21.formsite.com/iaMejf/ygbztobata/index.html"
            >
              Contribute to HAFTR
            </a>
            <a
              class="dropdown-item"
              href="https://www.haftr.org/apps/pages/index.jsp?uREC_ID=853476&type=d&pREC_ID=1219109"
            >
              Parnes HaYom
            </a>
            <a
              class="dropdown-item"
              href="https://www.haftr.org/apps/pages/index.jsp?uREC_ID=853476&type=d&pREC_ID=2280710"
            >
              Chesed Fund
            </a>
          </div>
        </div>
        <div className="dropdown">
          <button
            id="formsDropdownButton"
            className="nav-link mr-1 dropdown-toggle haftr-button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Forms
          </button>
          <div class="dropdown-menu" aria-labelledby="formsDropdownMenu">
            <a
              class="dropdown-item"
              href="https://fs21.formsite.com/iaMejf/v65e4l9yrg/index.html"
            >
              Shiva Form
            </a>
            <a
              class="dropdown-item"
              href="https://fs21.formsite.com/iaMejf/7umb9l4ykc/index.html"
            >
              Simcha Form
            </a>
          </div>
        </div>
        {user?.id ? (
          <>
            <div>
              <button
                className="nav-link mr-1 haftr-button"
                onClick={toAdminCenter}
              >
                {user?.admin ? "Admin Center" : "Find Others"}
              </button>
            </div>
            {!user?.admin && (
              <div>
                <button
                  className="nav-link mr-1 haftr-button"
                  onClick={() => history.push(browserRoutes.PROFILE_DETAIL)}
                >
                  Your Profile
                </button>
              </div>
            )}
            <div>
              <button
                className="btn selectwalletbutton my-2 my-sm-0 haftr-button"
                onClick={logout}
              >
                Log out
              </button>
            </div>
          </>
        ) : (
          <>
            <div>
              <button
                className="btn selectwalletbutton my-2 my-sm-0 haftr-button"
                onClick={() => history.push(browserRoutes.AUTH)}
              >
                Log In / Sign Up
              </button>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
