import React from "react";
import { onChange } from "./profileFunctions";

const Willing = ({ formData, setFormData }) => {
  const {
    alumniNewsletters,
    communicationsOutreach,
    classReunions,
    alumniEvents,
    fundraisingNetworking,
    dbResearch,
    alumniChoir,
  } = formData;

  return (
    <div style={{ borderTop: "1px solid #fff", color: "white" }}>
      <h5>Willing to Work On:</h5>
      <div className="row mt-3 pt-2">
        <div className="col-md-4 col-sm-6">
          <label className="labels">Alumni Newsletters</label>
          <input
            type="checkbox"
            className="form-control"
            name="alumniNewsletters"
            value={alumniNewsletters}
            checked={alumniNewsletters}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="labels">Communications and Outreach</label>
          <input
            type="checkbox"
            className="form-control"
            name="communicationsOutreach"
            value={communicationsOutreach}
            checked={communicationsOutreach}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="labels">Class Reunions</label>
          <input
            type="checkbox"
            className="form-control"
            name="classReunions"
            value={classReunions}
            checked={classReunions}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="labels">
            Coordinating and/or Hosting Alumni Events
          </label>
          <input
            type="checkbox"
            className="form-control"
            name="alumniEvents"
            value={alumniEvents}
            checked={alumniEvents}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="labels">
            Fundraising and Networking Initiatives
          </label>
          <input
            type="checkbox"
            className="form-control"
            name="fundraisingNetworking"
            value={fundraisingNetworking}
            checked={fundraisingNetworking}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="labels">Database Research</label>
          <input
            type="checkbox"
            className="form-control"
            name="dbResearch"
            value={dbResearch}
            checked={dbResearch}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="labels">Alumni Choir</label>
          <input
            type="checkbox"
            className="form-control"
            name="alumniChoir"
            value={alumniChoir}
            checked={alumniChoir}
            onChange={(e) => onChange(e, formData, setFormData)}
          />
        </div>
      </div>
    </div>
  );
};

export default Willing;
