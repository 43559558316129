import React, { useEffect, useState } from "react";
import IframeResizer from "iframe-resizer-react";

const Snapwidget = () => {
  const [height, changeHeight] = useState("347px");

  const onResize = () => {
    let width = document.body.clientWidth;
    if (width < 767) {
      changeHeight("213px");
    }
    if (width >= 767 && width < 999) {
      changeHeight("290px");
    }
    if (width >= 999 && width < 1200) {
      changeHeight("347px");
    }
  };

  useEffect(() => {
    onResize();
  }, []);

  window.addEventListener("resize", onResize);

  return (
    <div>
      <script src="https://snapwidget.com/js/snapwidget.js"></script>
      <div style={{ padding: "2%", height: "100%" }}>
        <h2 style={{ textAlign: "center" }}>
          <a href="https://www.instagram.com/haftralumni/?hl=en">
            @haftralumni
          </a>
        </h2>
        <h6 style={{ textAlign: "center" }}>
          Please share your smachot & life cycle events with Alumni@haftr.org so
          we can share them!
        </h6>
        <IframeResizer
          title="instagram snapwidget"
          src="https://snapwidget.com/embed/979180"
          className="snapwidget-widget"
          allowtransparency="true"
          frameBorder="0"
          scrolling="no"
          style={{
            minWidth: "100%",
            minHeight: height,
          }}
        />
      </div>
    </div>
  );
};

export default Snapwidget;
