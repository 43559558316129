import React from "react";

const Footer = () => {
  const hoverOverSmug = (e) => {
    e.preventDefault();
    e.target.src = e.target.src.replace("white", "green");
  };

  const hoverOffSmug = (e) => {
    e.preventDefault();
    e.target.src = e.target.src.replace("green", "white");
  };

  return (
    <div
      className="bg-blue py-4"
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <div>
        <small>
          Copyright &copy; 2021-{new Date().getFullYear()}. All rights reserved.
        </small>
      </div>
      <div>
        <small>Built by Ben Braunstein</small>
      </div>
      <span style={{ paddingRight: "15px" }}>
        <a
          href="http://www.facebook.com/OfficialHAFTR/"
          class="fa fa-facebook"
        ></a>
        <a
          href="http://www.instagram.com/haftrschool/"
          class="fa fa-instagram"
        ></a>
        <a href="https://vimeo.com/haftrvideos" class="fa fa-vimeo"></a>
        <a href="https://haftr.smugmug.com/" class="fa fa-smugmug">
          <img
            style={{ width: "30px" }}
            id="sumgimg"
            src="images/smugmug-white.png"
            alt="smugmug"
            onMouseEnter={hoverOverSmug}
            onMouseLeave={hoverOffSmug}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCgG7IbJcN1CVmiz4u2hRHnw"
          class="fa fa-youtube"
        ></a>
      </span>
    </div>
  );
};

export default Footer;
