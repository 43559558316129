import React from "react";
import { onChange } from "./profileFunctions";

const Names = ({ formData, setFormData }) => {
  const {
    title,
    firstname,
    middlename,
    lastname,
    maidenName,
    motherName,
    motherDeceased,
    fatherName,
    fatherDeceased,
    spouseName,
    spouseMaidenName,
  } = formData;

  return (
    <div className="row mt-3">
      <div className="col-md-4 col-sm-6">
        <label className="labels">Title</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Title"
          name="title"
          value={title}
          onChange={(e) => onChange(e, formData, setFormData)}
          aaa="aaa"
        />
      </div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">First Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter First Name"
          name="firstname"
          value={firstname}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">Middle Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Middle Name"
          name="middlename"
          value={middlename}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">Last Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Last Name"
          name="lastname"
          value={lastname}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">Maiden Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Maiden Name"
          name="maidenName"
          value={maidenName}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
      <div className="col-md-4 col-sm-6"></div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">Mother Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Mother Name"
          name="motherName"
          value={motherName}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">Mother Deceased</label>
        <input
          type="checkbox"
          className="form-control"
          name="motherDeceased"
          value={motherDeceased}
          checked={motherDeceased}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
      <div className="col-md-4 col-sm-6"></div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">Father Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Father Name"
          name="fatherName"
          value={fatherName}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">Father Deceased</label>
        <input
          type="checkbox"
          className="form-control"
          name="fatherDeceased"
          value={fatherDeceased}
          checked={fatherDeceased}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
      <div className="col-md-4 col-sm-6"></div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">Spouse Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Spouse Name"
          name="spouseName"
          value={spouseName}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
      <div className="col-md-4 col-sm-6">
        <label className="labels">Spouse Maiden Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Spouse Maiden Name"
          name="spouseMaidenName"
          value={spouseMaidenName}
          onChange={(e) => onChange(e, formData, setFormData)}
        />
      </div>
    </div>
  );
};

export default Names;
