const Routes = {
  LANDING_PAGE: "/",
  AUTH: "/auth",
  FORGOT_PASSWORD: "/forgot-password",
  SET_NEW_PASSWORD: "/set-password",
  EDIT_PROFILE: "/edit-profile",
  PROFILE_DETAIL: "/profile-detail",
  ALL_PROFILES: "/all-profiles",
  CREATE_PROFILES: "/create-profile",
};

export default Routes;
