import React, { Fragment, useState } from "react";

const Grandparents = ({ formData, setFormData }) => {
  const { grandparents } = formData;

  const [countFields, setCountFields] = useState([{ count: 0 }]);
  const [lastCount, setLastCount] = useState(0);

  const onAdd = (e) => {
    e.preventDefault();
    const lastFieldCount = countFields[countFields.length - 1].count; // last Field Count Value
    setCountFields([...countFields, { count: lastFieldCount + 1 }]);
    setFormData({
      ...formData,
      grandparents: [
        ...grandparents,
        // {
        //   grandfatherFirstname: "",
        //   grandmotherFirstname: "",
        //   lastname: "",
        // },
      ],
    });
    setLastCount(lastFieldCount + 1);
  };

  const onSubtract = (e) => {
    e.preventDefault();
    const allFields = countFields;
    allFields.pop(); // delete last value
    setCountFields([...allFields]);
    formData.grandparents.pop();
    setFormData({ ...formData });

    const lastFieldCount = countFields[countFields.length - 1].count; // last Field Value
    setLastCount(lastFieldCount);
  };

  const onChangeArrayType = (e, rowCount) => {
    let value = e.target.value;
    let newValue = [];

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }

    newValue = grandparents;
    newValue[rowCount] = {
      ...grandparents[rowCount],
      [e.target.name]: value,
    };

    setFormData({
      ...formData,
      grandparents: [...newValue],
    });
  };

  return (
    <div className="row mt-3 pt-2" style={{ borderTop: "1px solid #fff" }}>
      {countFields.map((item, index) => (
        <Fragment key={item.count}>
          <div className={`col-md-4 col-sm-6 ${item.count !== 0 && " mt-4"}`}>
            <label className="labels">Grandfather's Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Grandfather's Name"
              name="grandfatherFirstname"
              value={grandparents[item.count]?.grandfatherFirstname}
              onChange={(e) => onChangeArrayType(e, item.count)}
            />
          </div>
          <div className={`col-md-4 col-sm-6 ${item.count !== 0 && " mt-4"}`}>
            <label className="labels">Grandmother's Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Grandmother's Name"
              name="grandmotherFirstname"
              value={grandparents[item.count]?.grandmotherFirstname}
              onChange={(e) => onChangeArrayType(e, item.count)}
            />
          </div>
          <div className={`col-md-4 col-sm-6 ${item.count !== 0 && " mt-4"}`}>
            <label className="labels">Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Last Name"
              name="lastname"
              value={grandparents[item.count]?.lastname}
              onChange={(e) => onChangeArrayType(e, item.count)}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label className="labels">Grandfather Deceased</label>
            <input
              type="checkbox"
              className="form-control"
              name="grandfatherDeceased"
              value={grandparents[item.count]?.grandfatherDeceased}
              checked={grandparents[item.count]?.grandfatherDeceased}
              onChange={(e) => onChangeArrayType(e, item.count)}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label className="labels">Grandmother Deceased</label>
            <input
              type="checkbox"
              className="form-control"
              name="grandmotherDeceased"
              value={grandparents[item.count]?.grandmotherDeceased}
              checked={grandparents[item.count]?.grandmotherDeceased}
              onChange={(e) => onChangeArrayType(e, item.count)}
            />
          </div>
          <div className="col-md-4 col-sm-6"></div>
          {item.count === lastCount && (
            <div className="col-12" style={{ textAlign: "center" }}>
              <button onClick={onAdd}>Add Field</button>
              {item.count !== 0 && (
                <button onClick={onSubtract}>Delete Field </button>
              )}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Grandparents;
