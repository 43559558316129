import React from "react";
import Footer from "../Other/Footer";
import Navbar from "../Other/Navbar";
import Login from "./Login";
import Register from "./Register";

const Auth = () => {
  return (
    <div className="container-fluid">
      <Navbar />
      <div className="container">
        <div className="card card0 border-0">
          <div className="row d-flex">
            <Login />
            <Register />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Auth;
