import axios from "axios";
import React, { useState, useEffect } from "react";
import serverRoutes from "../../Routes/serverRoutes";
import moment from "moment";

const HappyBirthdays = () => {
  const [people, changePeople] = useState({
    // today: [
    //   {
    //     firstname: "Ben",
    //     lastname: "Haggerty",
    //   },
    // ],
    // upcoming: [
    //   {
    //     firstname: "Ben",
    //     lastname: "Haggerty",
    //     birthday: "03-22",
    //   },
    //   {
    //     firstname: "Ben",
    //     lastname: "Haggerty",
    //     birthday: "03-22",
    //   },
    // ],
  });
  const emojis = ["🥳", "🎂", "🎉", "🎁", "🎈", "🍰"];

  const getRandomEmoji = () => {
    return emojis[Math.floor(Math.random() * emojis.length)];
  };

  useEffect(() => {
    axios
      .get(`${serverRoutes.HBD}`)
      .then((res) => {
        console.log(res.data);
        changePeople(res.data);
      })
      .catch((err) => console.log(err.message));
  }, []);

  return (
    (people?.today?.length > 0 || people?.upcoming?.length > 0) && (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div>
            <img
              src="images/balloons-flipped.gif"
              alt="balloons"
              width="200px"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {people?.today?.length > 0 && (
              <>
                <h1>Today's Birthdays!</h1>
                {people.today.map((p) => (
                  <h4>
                    {getRandomEmoji()} {p.firstname} {p.lastname}
                    {p.highSchoolGradYear &&
                      `, Class of ${p.highSchoolGradYear}`}
                    {getRandomEmoji()}
                  </h4>
                ))}
              </>
            )}
            {people?.upcoming?.length > 0 && (
              <>
                <h1>Upcoming Birthdays!</h1>
                {people.upcoming.map((p) => (
                  <h4>
                    {getRandomEmoji()} {p.firstname} {p.lastname}
                    {p.highSchoolGradYear &&
                      `, Class of ${p.highSchoolGradYear}`}
                    {`, ${moment(p.birthday).format("MMMM Do")} `}
                    {getRandomEmoji()}
                  </h4>
                ))}
              </>
            )}
          </div>
          <div>
            <img src="images/balloons.gif" alt="balloons" width="200px" />
          </div>
        </div>
      </>
    )
  );
};

export default HappyBirthdays;
