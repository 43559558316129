const Routes = {
  USERS: "https://y0as6g37y0.execute-api.us-east-1.amazonaws.com/dev/users",
  LOGIN: "https://y0as6g37y0.execute-api.us-east-1.amazonaws.com/dev/login",
  FORGOT_PASSWORD:
    "https://y0as6g37y0.execute-api.us-east-1.amazonaws.com/dev/forgotpassword",
  SET_PASSWORD:
    "https://y0as6g37y0.execute-api.us-east-1.amazonaws.com/dev/setpassword",
  HBD: "https://y0as6g37y0.execute-api.us-east-1.amazonaws.com/dev/happybirthday",
  EXPORT_CSV:
    "https://y0as6g37y0.execute-api.us-east-1.amazonaws.com/dev/csv/alumni",
  AUTO_LOGIN:
    "https://y0as6g37y0.execute-api.us-east-1.amazonaws.com/dev/autologin",
  ALUMNI: "https://y0as6g37y0.execute-api.us-east-1.amazonaws.com/dev/alumni",
  // ALUMNI: "http://localhost:5000/alumni",
};

export default Routes;
