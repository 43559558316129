export const defaultValues = {
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  marriedName: "",
  motherName: "",
  motherDeceased: false,
  fatherName: "",
  fatherDeceased: false,
  spouseName: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  },
  homePhone: "",
  cellPhone: "",
  workPhone: "",
  emailAddress: "",
  middleschool: {
    name: "",
    yearStarted: "",
    yearEnded: "",
  },
  highschool: {
    name: "",
    yearStarted: "",
    yearEnded: "",
  },
  israelSchool: {
    name: "",
    yearStarted: "",
    yearEnded: "",
  },
  collegeAttended: {
    name: "",
    yearStarted: "",
    yearEnded: "",
  },
  gradSchools: [
    // {
    //   name: "",
    //   yearStarted: "",
    //   yearEnded: "",
    // },
  ],
  profession: [],
  // birthday: {},
  birthday: "",
  clubs: [],
  sportsTeams: [],
  awards: [],
  committees: [],
  oldAddresses: [
    // {
    //   line1: "",
    //   line2: "",
    //   city: "",
    //   state: "",
    //   zip: "",
    //   country: "",
    // },
  ],
  hillelDayCamp: {
    attended: false,
    startYear: "",
    endYear: "",
    specialty: "",
    camper: false,
    counselor: false,
  },
  hillelSleepCamp: {
    attended: false,
    startYear: "",
    endYear: "",
    specialty: "",
    camper: false,
    counselor: false,
  },
  hiliDayCamp: {
    attended: false,
    startYear: "",
    endYear: "",
    specialty: "",
    camper: false,
    counselor: false,
  },
  hiliWhiteCamp: {
    attended: false,
    startYear: "",
    endYear: "",
    specialty: "",
    camper: false,
    counselor: false,
  },
  hiliInternationalCamp: {
    attended: false,
    startYear: "",
    endYear: "",
    specialty: "",
    camper: false,
    counselor: false,
  },
  hili: false,
  hillel: false,
  haftr: false,
  parentOfStudent: false,
  boards: [],
  alumniPositions: [],
  siblings: [
    // {
    //   firstname: "",
    //   lastname: "",
    //   yearCompleted: "",
    //   middleSchool: {
    //     name: "",
    //     yearStarted: "",
    //     yearEnded: "",
    //   },
    //   highSchool: {
    //     name: "",
    //     yearStarted: "",
    //     yearEnded: "",
    //   },
    // },
  ],
  children: [
    // {
    //   firstname: "",
    //   lastname: "",
    //   graduationYear: "",
    // },
  ],
  grandparents: [
    // {
    //   grandfatherFirstname: "",
    //   grandfatherDeceased: "",
    //   grandmotherFirstname: "",
    //   grandmotherDeceased: "",
    //   lastname: "",
    // },
  ],
  comment: "",
  profilePictureURL: "",
};
