import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import serverRoutes from "../../Routes/serverRoutes";
import browserRoutes from "../../Routes/browserRoutes";

import setAuthToken from "../../utils/setAuthToken";
import Footer from "../Other/Footer";
import Navbar from "../Other/Navbar";
import { UserContext } from "../../App";

const ForgotPassword = (match) => {
  const [user, setUser] = React.useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(match.location.search);

  const history = useHistory();
  const [formData, setFormData] = useState({
    email: urlParams.get("email") || "",
  });
  const { email } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post(serverRoutes.FORGOT_PASSWORD, formData).then((res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(
          "Please check your email for a token to reset your password"
        );
        history.push(
          `${browserRoutes.SET_NEW_PASSWORD}?email=${formData.email}`
        );
      } else {
        toast.error("Something went wrong");
      }
    });
    setLoading(false);
  };

  return (
    <div className="container">
      <Navbar />
      <div className="card card0 border-0">
        <div className="card2 card border-0 px-4 py-5">
          <div className="row mb-4 px-3">
            <h6 className="mb-0 mr-4 mt-2">Forgot Password</h6>
          </div>
          <div className="row px-3 mb-4">
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Email Address</h6>
              </label>
              <input
                className="mb-4"
                type="email"
                placeholder="Enter Email Address"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="row mb-3 px-3">
              <button
                type="submit"
                className="btn btn-blue text-center"
                disabled={loading}
              >
                {loading && (
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ width: "1.5rem", height: "1.5rem" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                Reset Password
              </button>
            </div>
          </form>
          <p
            style={{ cursor: "pointer", color: "#007bff" }}
            onClick={() => history.push(browserRoutes.SET_NEW_PASSWORD)}
          >
            Already have your reset token?
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
