import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import browserRoutes from "../../Routes/browserRoutes";
import { defaultValues } from "../CreateProfile/formDataValues";
import serverRoutes from "../../Routes/serverRoutes";
import axios from "axios";

import { UserContext } from "../../App";
import { toast } from "react-toastify";
import Navbar from "../Other/Navbar";

const ProfileDetail = ({}) => {
  const [user, setUser] = React.useContext(UserContext);

  const [profileData, setProfileData] = useState(defaultValues);
  const {
    userId,
    status,
    title,
    firstname,
    middlename,
    lastname,
    highSchoolGradYear,
    marriedName,
    motherName,
    fatherName,
    spouseName,
    address,
    homePhone,
    cellPhone,
    workPhone,
    emailAddress,
    middleschool,
    highschool,
    israelSchool,
    collegeAttended,
    gradSchools,
    profession,
    birthday,
    clubs,
    sportsTeams,
    awards,
    committees,
    oldAddresses,
    hillelDayCamp,
    hillelSleepCamp,
    hiliDayCamp,
    hiliWhiteCamp,
    hiliInternationalCamp,
    hili,
    hillel,
    haftr,
    parentOfStudent,
    boards,
    alumniPositions,
    siblings,
    children,
    comment,
    id,
    isPublic,
    profilePictureURL,
  } = profileData;
  const history = useHistory();

  const toEditProfile = (e) => {
    e.preventDefault();
    let ROUTE = browserRoutes.EDIT_PROFILE;
    if (user?.admin) {
      ROUTE += "?id=" + window.location?.search?.substring(4);
    }
    history.push(ROUTE);
  };
  const toCreateProfile = (e) => {
    e.preventDefault();
    history.push(browserRoutes.CREATE_PROFILES);
  };

  const goPublic = (e, alumniId) => {
    e.preventDefault();

    const queryParams = window.location?.search?.substring(4);
    axios
      .patch(`${serverRoutes.ALUMNI}/${alumniId || queryParams}/gopublic`)
      .then((res) => {
        setProfileData({ ...res.data, status: status });

        toast.success(
          "Congrats. This profile is now public and others can view your contact details and profile picture"
        );
      })
      .catch((err) => {
        toast.error("Error making this profile public");
        console.log(err);
      });
  };

  const goPrivate = (e, alumniId) => {
    e.preventDefault();

    const queryParams = window.location?.search?.substring(4);
    axios
      .patch(`${serverRoutes.ALUMNI}/${alumniId || queryParams}/goprivate`)
      .then((res) => {
        setProfileData({ ...res.data, status: status });

        toast.success(
          "Your profile is now private. No one can view your contact details or profile picture"
        );
      })
      .catch((err) => {
        toast.error("Error making your profile private");
        console.log(err);
      });
  };

  const queryParams = window.location?.search?.substring(4);
  useEffect(() => {
    if (user?.alumniId || queryParams) {
      axios
        .get(`${serverRoutes.ALUMNI}/${queryParams || user?.alumniId || 0}`)
        .then((res) => {
          setProfileData(res.data);
        })
        .catch((err) =>
          console.log(err.response?.data?.message || "server error")
        );
    }
  }, [user?.alumniId, queryParams]);

  const approveDenyUser = (e, approveOrDeny) => {
    e.preventDefault();

    axios
      .patch(`${serverRoutes.USERS}/${userId}/${approveOrDeny}`)
      .then((res) => {
        setProfileData({ ...profileData, status: res.data.status });
        toast.success(
          approveOrDeny === "approve" ? "User Approved!" : "User Denied!"
        );
      })
      .catch((err) => {
        toast.error(
          approveOrDeny === "approve"
            ? "User Approval Failed"
            : "User Denial Failed"
        );
      });
  };

  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    setUser(null);
    history.push(browserRoutes.AUTH);
  };

  const toAdminCenter = (e) => {
    e.preventDefault();
    history.push(browserRoutes.ALL_PROFILES);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <Navbar />
        <div className="container">
          <div
            className="container rounded mt-1 mb-5"
            style={{
              backgroundColor: "#1A237E",
            }}
          >
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                  <img
                    className="mt-5"
                    src={
                      profilePictureURL
                        ? profilePictureURL
                        : "images/profile-img.png"
                    }
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    alt="..."
                  />
                  {(user?.alumniId === id || user?.admin) && (
                    <div className="mt-5 text-center">
                      {!user?.admin && !user?.alumniId ? (
                        <button
                          className="btn btn-primary profile-button"
                          type="button"
                          onClick={toCreateProfile}
                        >
                          Create Profile
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary profile-button"
                          type="button"
                          onClick={toEditProfile}
                        >
                          Edit Profile
                        </button>
                      )}
                      {!isPublic ? (
                        <button
                          className="btn btn-primary profile-button"
                          type="button"
                          onClick={(e) => goPublic(e, user?.alumniId)}
                        >
                          Go Public
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary profile-button"
                          type="button"
                          onClick={(e) => goPrivate(e, user?.alumniId)}
                        >
                          Go Private
                        </button>
                      )}
                    </div>
                  )}
                  {user?.admin && (
                    <div className="mt-5 text-center">
                      <p style={{ color: "white" }}>
                        Current Status: {status || "PENDING"}
                      </p>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={(e) => approveDenyUser(e, "approve")}
                      >
                        Approve Profile
                      </button>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={(e) => approveDenyUser(e, "deny")}
                      >
                        Deny Profile
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-8">
                <div className="p-3 py-5">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <ul className="row profile-detail">
                        <li className="col-12 col-md-6">
                          <span>First Name: </span>
                          <span>{firstname || ""}</span>
                        </li>
                        <li className="col-12 col-md-6">
                          <span>Last Name: </span>
                          <span>{lastname || ""}</span>
                        </li>
                      </ul>
                      <ul className="row profile-detail">
                        <li className="col-12 col-md-6">
                          <span>Email Address: </span>
                          <span>{emailAddress || ""}</span>
                        </li>
                        <li className="col-12 col-md-6">
                          <span>Class of: </span>
                          <span>{highSchoolGradYear || ""}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfileDetail;
