import React from "react";
import Navbar from "../Other/Navbar";
import Footer from "../Other/Footer";
import HappyBirthdays from "./HappyBirthdays";
import Snapwidget from "./Snapwidget";

const LandingPage = () => {
  return (
    <div className="container-fluid">
      <Navbar />
      <div className="container">
        <div className="card card0 border-0">
          <div>
            <Snapwidget />
          </div>
          <div>
            <HappyBirthdays />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
